import axios from 'axios'

const api = process.env.VUE_APP_ROOT_API + '/v2/service-catalog/';
const apiKey = process.env.VUE_APP_API_KEY;

export default {
  get(organisationListRequest) {
    return axios.post(api, mergeApiKey(organisationListRequest))
  },
  getByOrganisationCode(organisationCode) {
    return axios.post(api + organisationCode, mergeApiKey({}))
  }

}

function mergeApiKey(request) {
  return {...request, ...{apiKey: apiKey}};
}
