<template>
  <div class="search-bar">
    <div class="logo">
      <router-link to="/">
        <img src="../assets/logo.png" alt="Kennisnet Entree logo" />
      </router-link>
    </div>
    <div class="search-bar-subtitle">
      <span>
        <strong>
          FEDERATIE CATALOGUS
        </strong>
      </span>
    </div>
    <div class="search-bar-input" >
      <div class="row search-box">
        <input type="text" maxlength="100" @keyup.enter="callback()" class="text-query" v-model="searchQuery.search.query" placeholder="organisatie zoeken (op naam)"/>
        <span class="search-icon" @click="callback()">
          <font-awesome-icon icon="search"/>
        </span>
      </div>
      <div class="row search-bar-filters">
        <label><input type="checkbox" value="po" v-model="searchQuery.filter.po">po</label>
        <label><input type="checkbox" value="vo" v-model="searchQuery.filter.vo">vo</label>
        <label><input type="checkbox" value="mbo" v-model="searchQuery.filter.mbo">mbo</label>
        <label><input type="checkbox" value="hbo" v-model="searchQuery.filter.hbo">hbo</label>
        <label><input type="checkbox" value="wo" v-model="searchQuery.filter.wo">wo</label>
      </div>
    </div>
    <div v-if="goBack" class="go-back" v-on:click="goToSearch">&lt; Terug naar overzicht</div>
  </div>
</template>

<script>
  export default {
    props: {
      searchQueryProp: Object,
      callback: Function,
      goBack: Boolean
    },
    created() {
      this.origSearchQuery = Object.assign({}, this.searchQueryProp)

      if (this.query !== null && this.query !== undefined) {
        this.search = {...this.search, ...this.query}
        this.search.paging.index = 0
      }
    },
    data() {
      return {
        searchQuery: this.searchQueryProp
      }
    },
    methods: {
      goToSearch() {
        this.$router.push({name: 'home', params: {query: JSON.stringify(this.origSearchQuery)}})
      }
    }
  }
</script>

<style lang="scss">
  @import '../assets/scss/SearchBar.scss';
</style>
