<template>
  <span class="yes-no-icon">
    <font-awesome-icon v-if="this.flag" icon="check"/>
    <font-awesome-icon v-else icon="times"/>
  </span>
</template>

<script>
  export default {
    name: 'YesNoIcon',
    props: ['flag']
  }
</script>

<style lang="scss">
  @import '../assets/scss/YesNoIcon.scss';
</style>
