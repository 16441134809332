import App from './App.vue'
import {createRouter, createWebHistory} from "vue-router";
import SearchPage from "@/views/SearchView";
import DetailsView from "@/views/DetailsView";
import { createApp } from 'vue'
import BootstrapVue3 from "bootstrap-vue-3";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faCheck, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'bootstrap/dist/css/bootstrap.css'

library.add(faTimes, faCheck, faSearch)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: SearchPage,
      props: true
    },
    {
      path: '/details/:name',
      name: 'details',
      component: DetailsView,
      props: true
    }
  ]
})

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .use(BootstrapVue3)
  .mount('#app')
