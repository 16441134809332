<template>
  <b-card class="organisation-card" @click="callback(item)">
    <b-row>
      <b-col class="header text-start" cols="12">
        <span>{{item.name}}</span>
      </b-col>
      <b-col class="organisation-logo" cols="12">
        <img align="left" v-bind:src="item.serviceLogoPath ? item.serviceLogoPath : require('../assets/no_logo.png')"
             alt="service logo"/>
      </b-col>
      <b-col cols="12">
        <p class="organisation-description card-text">
          {{breakContent(item.description, descriptionCharLimit)}}
          <a v-if="null != item.description && item.description.length > descriptionCharLimit" href="">lees meer</a>
        </p>
      </b-col>
      <div class="w-100 blank-20"></div>
      <b-col cols="5">
        <p class="card-text">URL van de dienst</p>
      </b-col>
      <b-col cols="7" class="text-start">
        <a class="card-text" :href="getUrl(item.url)" v-b-tooltip:hover :title="item.url">
          {{breakContent(item.url, urlCharLimit)}}
        </a>
      </b-col>
      <div class="w-100 blank-20"></div>
      <b-col cols="12" class="text-end">
        <span>Sector: {{getSectors(item)}} </span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
  import UtilsService from '../services/utils.service';

  export default {
    props: ['item', 'callback'],
    methods: {
      getSectors: UtilsService.getSectors,
      getUrl: UtilsService.getUrl,
      breakContent: function(text, limit) {
        return null !== text && text.length >= limit ?
            text.substr(0, limit-1) + '...' :
            (null !== text && text.length > 1 ? text : "onbekend");
      }
    },
    data() {
      return {
        descriptionCharLimit: 256,
        urlCharLimit: 35
      }
    }
  }
</script>

<style lang="scss">
  @import '../assets/scss/OrganisationCard';
</style>
