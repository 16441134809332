<template>
  <div class="details-view">
    <search-bar :searchQueryProp="search" :callback="goToSearch" :goBack="goBack"></search-bar>
    <b-container v-if="loading">
      <div>
        Loading
      </div>
    </b-container>
    <b-container class="details-container" v-if="service">
      <b-row class="text-start description">
        <h3 class="w-100">
          {{ getText(service.name) }}
        </h3>
        <hr class="w-100">
      </b-row>
      <b-row class="organisation-logo">
        <img v-bind:src="service.serviceLogoPath" alt="Service provider logo"/>
      </b-row>
      <b-row>
        <b-card class="organisation-card w-100 bottom-padding-15">
          <p class="header">Algemene gegevens</p>
          <div class="card-content">
            <b-row>
              <b-col cols="4">
                <p class="card-text">Dienstbeschrijving</p>
              </b-col>
              <b-col cols="8">
                <p class="card-text">{{ getText(service.description) }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <p class="card-text">URL van de dienst</p>
              </b-col>
              <b-col cols="8">
                <a class="card-text" :href="getUrl(service.url)">{{ getText(service.url) }}</a>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <p class="card-text">Sector(en)</p>
              </b-col>
              <b-col cols="8">
                <p class="card-text">{{ getSectors(service) }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <p class="card-text">Aanbieder:</p>
              </b-col>
              <b-col cols="8">
                <p class="card-text">{{ getText(service.organisationName) }}</p>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card class="organisation-card w-100 bottom-padding-15">
          <p class="header">Supportgegevens</p>
          <div class="card-content">
            <b-row>
              <b-col cols="4">
                <p class="card-text">URL support</p>
              </b-col>
              <b-col cols="8">
                <p class="card-text">{{ getText(service.supportUrl) }} {{ unknownText }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <p class="card-text">Telefoonnummer support</p>
              </b-col>
              <b-col cols="8" v-if="null != service.supportPhone && service.supportPhone !== ''">
                <a class="card-text" :href="'tel:' + getText(service.supportPhone)">{{ getText(service.supportPhone) }}</a>
              </b-col>
              <b-col cols="8" v-if="null == service.supportPhone || service.supportPhone === ''">
                <p class="card-text">{{ getText(service.supportPhone) }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <p class="card-text">E-mail support</p>
              </b-col>
              <b-col cols="8" v-if="null != service.supportEmail && service.supportEmail !== ''">
                <a class="card-text" :href="'mailto:' + getUrl(service.supportEmail)">{{ getText(service.supportEmail) }}</a>
              </b-col>
              <b-col cols="8" v-if="null == service.supportEmail || service.supportEmail === ''">
                <p class="card-text">{{ getText(service.supportEmail) }}</p>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card class="organisation-card w-100">
          <p class="header">Toegangsgegevens</p>
          <div class="card-content">
            <b-row>
              <b-col cols="4">
                <p class="card-text">Entree-account</p>
              </b-col>
              <b-col cols="8">
                <yes-no-icon :flag="service.entreeAccountLoginEnabled"/>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <p class="card-text">Licentie vereist</p>
              </b-col>
              <b-col cols="8">
                <yes-no-icon :flag="service.licenseRequired"/>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <p class="card-text">Gratis of betaald</p>
              </b-col>
              <b-col cols="8">
                <p class="card-text">{{ getPaid(service.servicePricing) }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <p class="card-text">Privacyconvenant status</p>
              </b-col>
              <b-col cols="8">
                <yes-no-icon :flag="service.signedPrivacyConvention"/>
              </b-col>
            </b-row>
          </div>
        </b-card>
        </b-row>
       <b-row class="last-updated text-end">
        <span class="text-muted">Laatst gewijzigd: {{ formatDate(this.service.lastUpdateDate) }}</span>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SearchBar from '../components/SearchBar'
  import UtilsService from '../services/utils.service'
  import OrganisationCatalogAPI from '../api/catalog.api'
  import YesNoIcon from '../components/YesNoIcon'
  import moment from "moment";

  export default {
    name: 'DetailsView',
    components: {SearchBar, YesNoIcon},
    props: ['item', 'query', 'goBackProp'],
    methods: {
      fetchData() {
        this.error = this.service = null
        this.loading = true
        OrganisationCatalogAPI.getByOrganisationCode(this.$route.params.name)
          .then(response => {
            this.loading = false
            this.service = response.data
          })
      },
      goToSearch() {
        this.$router.push({name: 'home', params: {query: JSON.stringify(this.search)}})
      },
      formatDate(value) {
        if (value) {
          return moment(String(value)).format('DD/MM/YYYY')
        }
      },
      getUrl: UtilsService.getUrl,
      getText: UtilsService.getText,
      getSectors: UtilsService.getSectors,
      getPaid: UtilsService.getPaid
    },
    created() {
      if (this.item === null || this.item === undefined) {
        this.fetchData()
      } else {
        this.service = JSON.parse(this.item)
      }
      if (this.query !== null && this.query !== undefined) {
        this.search = {...this.search, ...JSON.parse(this.query)}
      }
      if (this.goBack === null || this.goBack === undefined) {
        this.goBack = true;
      }
      window.scrollTo(0,0)
    },
    data() {
      return {
        service: null,
        unknownText: null,
        loading: false,
        search: {
          search: {
            query: ''
          },
          filter: {
            po: false,
            vo: false,
            mbo: false,
            hbo: false,
            wo: false,
          },
        },
        goBack: this.goBackProp === 'true'
      }
    }
  }
</script>

<style lang="scss">
  @import '../assets/scss/App';
  @import '../assets/scss/DetailsView';
</style>
