<template>
  <div>
    <search-bar :searchQueryProp="search" :callback="load" :goBack="false"></search-bar>
    <b-container>
      <b-row class="text-start description">
        <h3 class="w-100">
          Overzicht van aangesloten diensten
        </h3>
        <hr class="w-100">
      </b-row>
      <b-row>
        <b-card-group deck class="row">
          <b-col md="6" v-for="item in this.list"
                 v-bind:data="item"
                 v-bind:key="item.name"
                 class="organisation-card-deck">
            <organisation-card :item="item" :callback="goToDetails"></organisation-card>
          </b-col>
        </b-card-group>
      </b-row>
      <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler">
        <template #no-more>
          &nbsp;
        </template>
        <template #no-results>
          Er zijn geen diensten gevonden die aan jouw zoekcriteria voldoen.
        </template>
      </infinite-loading>
    </b-container>
  </div>
</template>

<script>
  import SearchBar from "../components/SearchBar"
  import InfiniteLoading from 'vue-infinite-loading';
  import OrganisationCatalogAPI from "../api/catalog.api"
  import OrganisationCard from "../components/OrganisationCard";

  export default {
    name: 'SearchPage',
    components: {
      OrganisationCard,
      SearchBar,
      InfiniteLoading,
    },
    props: ["query", "goBack"],
    data () {
      return {
        search: {
          search: {
            query: ''
          },
          filter: {
            po: false,
            vo: false,
            mbo: false,
            hbo: false,
            wo: false,
          },
          paging: {
            size: 8,
            index: 0
          },
          sort: {
            key: 'name',
            order: 'asc'
          }
        },
        list: [],
      }
    },
    created() {
      if (this.query !== null && this.query !== undefined) {
        this.search = {...this.search, ...JSON.parse(this.query)}
        this.search.page = 0
        this.load()
      }
    },
    methods: {
      goToDetails: function (item) {
        this.$router.push({name: 'details', params: {name: item.id, item: JSON.stringify(item), query: JSON.stringify(this.search), goBackProp: true}})
      },
      infiniteHandler ($state) {
        OrganisationCatalogAPI.get(this.search).then(({data}) => {
          this.loadPage(data, $state)
        });
      },
      load () {
        window.scrollTo(0,0)
        this.search.paging.index = 0
        let loaderState = this.$refs.infiniteLoading.stateChanger
        loaderState.reset()
        this.list = []
      },
      loadPage (data, loaderState) {
        if (data.totalCount > (this.search.paging.index*this.search.paging.size)) {
          this.search.paging.index > 0 && this.list.length < data.totalCount ? this.list.push(...data.records) : this.list = data.records;
          this.search.paging.index += 1;
          loaderState.loaded();
        } else {
          loaderState.complete();
        }
      }
    },

  }
</script>

<style lang="scss">
  @import '../assets/scss/App';
  @import '../assets/scss/SearchView';
</style>
