const unknownItem = 'onbekend';
export default {
  getSectors(item) {
    let sectors = [];
    if (item.po) {
      sectors.push('po');
    }
    if (item.vo) {
      sectors.push('vo');
    }
    if (item.mbo) {
      sectors.push('mbo');
    }
    if (item.hbo) {
      sectors.push('hbo');
    }
    if (item.wo) {
      sectors.push('wo');
    }
    return sectors.length > 0 ? sectors.join(', ') : unknownItem;
  },
  getPaid(item) {
    switch (item)
    {
      case 'FREE': return 'gratis';
      case 'PAID': return 'betaald';
      case 'PARTIALLY_FREE_PAID': return 'zowel gratis als betaald';

      default: return unknownItem;
    }
  },
  getUrl: function (url) {
    return null !== url && url.length > 1 ? url : false;
  },
  getText(text) {
    return null !== text && '' !== text ? text : unknownItem;
  },
}
